<template>
    <main>
        <!-- services Area Start-->
        <div class="services-area section-padding2">
            <div class="container">
                <!-- section tittle -->
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-tittle text-center">
                            <h2>Nossos Serviços</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div v-for="service in services" :key="service.id" class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-services text-center mb-30">
                            <div class="services-icon">
                                <span class="flaticon-checklist"></span>
                            </div>
                            <div class="services-caption">
                                <h4>{{ service.service }}</h4>
                                <p>{{ service.description }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- services Area End-->
    </main>
</template>

<script>
    export default {
        name: "ServicesContent",
        data() {
            return {
                services: [

                    {
                        id: 1,
                        service: 'Gestão Estratégica de Capital Humano',
                        description: 'Prestamos serviços de consultoria em Gestão do Capital Humano e Formação, com enfoque para as áreas Comportamental e de Liderança'
                    },
                    {
                        id: 2,
                        service: 'Desenvolvimento Organizacional',
                        description: 'Oferecemos serviços diversos virados ao fortalecimento da capacidade institucional e aumento da eficiência e produtividade das organizações'
                    },
                    {
                        id: 3,
                        service: 'Coaching Executivo & de Negócios',
                        description: 'Apoiamos líderes a reescreverem as suas narrativas e a estabelecerem um roadmap robusto, rumo à realização plena do seu pontencial e sonhos'
                    },
                    {
                        id: 4,
                        service: 'Formação',
                        description: 'Fornecemos serviços de formação nas áreas técnica, comportamental, de gestão e liderança, desenhados à medida das necessidades dos clientes'

                    },
                    {
                        id: 5,
                        service: 'Produtividade dos Colaboradores',
                        description: 'Facilitamos a implementação de iniciativas para o melhoramento da produtividade dos colaboradores. Ajudamos os clientes a fazer um diagnóstico 360° e a desenhar soluções à medida, assentes na digitalização'
                    },
                    {
                        id: 6,
                        service: 'Executive Search',
                        description: 'Ajudamos nossos clientes a mapear e atrair talento para posições seniores e executivas, tanto no mercado nacional, como na diáspora'
                    },
                ]
            }
        },
    }
</script>

<style scoped>

</style>
