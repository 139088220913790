<template>
    <main>
        <vue-headful
                title="Lean2Grow | Consultoria e Serviços"
                description="O nosso trabalho esta assente nos principios de Mutualiy, Enablement & Growth. Assim,
                focamos em solucoes mutuamente beneficas, intervimos como catalizadores e facilitadores, sempre focados
                no crescimento sustentavel dos negocios dos nossos clientes."
        />
        <slider></slider>
        <!-- Team-profile Start -->
        <div id="porque-lean2grow" class="team-profile team-padding animated fadeInLeft">
            <div class="container">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-profile mb-30">
                            <!-- Front -->
                            <div class="single-profile-front">
                                <div class="profile-img">
                                    <img src="../assets/img/team/team_1.jpg" alt="">
                                </div>
                                <div class="profile-caption">
                                    <h4 class=""><a href="#">Ajudamos a fortalecer as capacidades de nossos clientes</a>
                                    </h4>
                                    <ul>
                                        <li><p>Desenho Organizacional</p></li>
                                        <li><p>Avaliação da Capacidade Organizacional</p></li>
                                        <li><p>Melhoramento da Eficiência Organizacional</p></li>
                                        <li><p>Reengenharia de Processos</p></li>
                                        <li><p>Reestruturação Organizacional</p></li>
                                        <li><p>Gestão da Mudança</p></li>
                                        <li><p>Cultura Organizacional</p></li>
                                        <li><p>Produtividade dos Colaboradores</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-profile mb-30">
                            <!-- Front -->
                            <div class="single-profile-front">
                                <div class="profile-img">
                                    <img src="../assets/img/team/team_2.jpg" alt="">
                                </div>
                                <div class="profile-caption">

                                    <h4 class=""><a href="#">Reposicionamos a Gestão do Capital Humano das empresas</a>
                                    </h4>
                                    <ul>
                                        <li><p>Soluções de Formação & Desenvolvimento do Pessoal</p></li>
                                        <li><p>Engajamento dos Colaboradores</p></li>
                                        <li><p>Otimização de Custos com o Pessoal</p></li>
                                        <li><p>Gestão de Talentos & Liderança</p></li>
                                        <li><p>Alinhamento das Soluções de Capital Humano com a Estratégia do
                                            negócio</p></li>
                                        <li><p>People Analytics & Reporting</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-profile mb-30">
                            <!-- Back -->
                            <div class="single-profile-back-last">
                                <h2 class=""><a href="#">Porquê Escolher a Lean2Grow</a></h2>
                                <ul>
                                    <li><p>Profundos conhecimentos e experiência prática nas nossas áreas de atuação</p></li>
                                    <li><p>Conhecimento profundo do mercado e dos desafios dos empreendedores</p></li>
                                    <li><p>Otimização de Custos com o Pessoal</p></li>
                                    <li><p>Foco aguçado na produtividade dos lideres e das organizações</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Team-profile End-->
        <about-content></about-content>
        <services-content></services-content>

    </main>
</template>

<script>
    import Slider from './Slider'
    import AboutContent from './About/AboutContent'
    import ServicesContent from './Services/ServicesContent'

    export default {
        name: "Main",
        components: {
            Slider,
            AboutContent,
            ServicesContent
        }
    }
</script>

<style scoped>
    .testimonial-icon {
        padding-bottom: 135px;
    }

    .testimonial-area {
        background: #f8fafe
    }

</style>
