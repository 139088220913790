<template>
    <carousel :nav="false"
              :items="1"
              :dots="false">
        <!--              :autoplay="true">-->

        <!-- slider Area Start-->
        <div class="slider-area ">
            <!-- Mobile Menu -->
            <div class="slider-active">
                <div class="single-slider slider-height d-flex align-items-center"
                     :style="{'background-image': 'url(' + require('../assets/img/hero/h2_hero.jpg') + ')'}">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-8">
                                <div class="hero__caption">
                                    <p class="animated fast fadeInLeft">Bem-Vindo a Lean2Grow</p>
                                    <h1 class="animated fadeInLeft delay-1s">Ajudamos líderes e empresas a elevarem a
                                        sua produtividade</h1>
                                    <!-- Hero-btn -->
                                    <div class="hero__btn animated fadeInLeft delay-2s">
                                        <a href="#porque-lean2grow" class="btn hero-btn">Saber mais</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </carousel>
</template>

<script>
    import carousel from 'vue-owl-carousel'

    export default {
        name: "Slider",
        data() {
            return {
                image: "../assets/img/hero/h2_hero.jpg"
            }
        },
        components: {carousel},
    }
</script>

<style scoped>

</style>
